import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import {
  ChartNumberData,
  ChartNumberItem,
  ChartOtherProgramInfo,
  OtherProgramTitle,
  Program,
  SelectedFilters,
  VerticalChartDataSet,
} from '../../model/customtypes-model.interface';
import { SharedService } from '../../../services/shared.service';
import { OtherProgramService } from '../../../services/other.program.service';
import {
  ChartType,
  studentEnrollmentChartCount,
} from '../../../services/enum/shared.enum';
import { dataEmpty } from '../chart-custom-plugins';
@Component({
  selector: 'app-vertical-bar-chart',
  styleUrls: ['./vertical-bar-chart.component.scss'],
  templateUrl: './vertical-bar-chart.component.html',
})
export class VerticalBarChartComponent implements OnInit {
  public totalCounts: Array<number>;
  @ViewChild(BaseChartDirective)
  public chart?: BaseChartDirective;
  public ChartLabels: Array<string> = ['554', '5533', '31231'];
  public colors = ['#84C7F0', '#F3D278', '#FA9885', '#D9A9F4', '#F5B171'];
  public hoverColors = ['#84C7F0', '#F3D278', '#FA9885', '#D9A9F4', '#F5B171'];
  public chartData: ChartData<'bar'> = {
    datasets: this.colors.map((color, index) =>
      this.generateDataset(color, this.hoverColors[index])
    ),
    labels: this.ChartLabels,
  };
  public isShowOther: boolean = false;
  public chartType: ChartConfiguration<'bar'>['type'] = 'bar';
  public chartPlugin: any = [ChartDataLabels, dataEmpty];
  public chartOption: any = {
    animation: false,
    layout: {
      padding: window.innerWidth > 700 ? 10 : 0,
    },
    maintainAspectRatio: window.innerWidth > 490,
    plugins: {
      align: 'start',
      border: false,
      datalabels: {
        color: 'black',
        font: {
          size: window.innerWidth >= 3300 ? 20 : 12,
          weight: 'normal',
        },
      },
      legend: {
        display: false,
        labels: {
          boxHeight: window.innerWidth > 700 ? 15 : 10,
          boxWidth: window.innerWidth > 700 ? 15 : 10,
          pointStyle: 'rectRounded',
          color: '#526289',
          textAlign: 'center',
          usePointStyle: true,
        },
        padding: {
          bottom: 20,
        },
        position: 'top',
      },
      text: false,
      text1: false,
      doughnutLabelsLine: false,
      customCanvasBackgroundColor: false,
      greaterThanFiveCount: false,
      greaterThanFiveCountAGD: false,
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        min: 0,
        max: 3,
        border: {
          borderDash: [1, 1],
          color: '#1F356C',
          display: true,
          width: 1,
        },
        grid: {
          display: false,
          offset: true,
        },
        stacked: true,
        ticks: {
          color: '#1F356C',
          font: {
            weight: 'bold',
            size: window.innerWidth >= 3300 ? 20 : 10,
          },
        },
        title: {
          align: 'center',
          color: '#526289',
          display: true,
          text: 'Student Enrollment Year',
          font: {
            size: window.innerWidth >= 3300 ? 20 : 10,
          },
        },
      },
      y: {
        border: {
          dash: [2, 4],
          display: false,
        },
        stacked: true,
        suggestedMax: 10,
        ticks: {
          color: '#1F356C',
          font: {
            weight: 'bold',
            size: window.innerWidth >= 3300 ? 20 : 10,
          },
          maxTicksLimit: window.innerWidth > 700 ? 10 : 50,
        },
        title: {
          align: 'start',
          color: '#526289',
          display: true,
          text: 'No.of Students',
          font: {
            size: window.innerWidth >= 3300 ? 20 : 10,
          },
        },
      },
    },
  };
  public graphBackgroundColor: string[];
  public graphLightColor: boolean;
  public programInfo: Program[];
  public tempChartLabels: string[] = [];
  public tempChartData: ChartData<'bar'>;
  public totalCountsFull: number[] = [];
  public chartOptionXMin: number =
    parseInt(`${new Date().getFullYear()}`.slice(3, 4), 10) - 2;
  public chartOptionXMax: number =
    parseInt(`${new Date().getFullYear()}`.slice(3, 4), 10) + 1;
  public disableLefttrue: boolean = false;
  public disableRighttrue: boolean = true;
  public startX: number = 0;
  public disableArrowTrue: boolean = false;

  @Input() public otherProgramDetails: ChartOtherProgramInfo[] = [];
  @Input() public labelInfo: string[] = [];
  @Input() public totalInfo: any[] = [];
  @Input() public totalYearWiseInfo: any = [];
  @Input() public chartNumbers: ChartNumberData[] = [];
  public filteredData = {};
  public currentYear: number;
  public showCurrentyearLength: number;
  public otherPrgmLabel: any[] = [];
  constructor(
    public sharedService: SharedService,
    public otherProgramService: OtherProgramService
  ) {
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
    this.tempChartLabels = JSON.parse(JSON.stringify(this.ChartLabels));
    this.tempChartData = JSON.parse(JSON.stringify(this.chartData));
  }

  @Input() set totals(data: any) {
    this.totalCounts = data;
  }

  // Getting dataset chart number

  @Input() set chartDatSets(data: VerticalChartDataSet) {
    if (data) {
      this.showCurrentyearLength = data.labels.length;
      const currentYearData = data.labels[data.labels.length - 1];
      this.checkCurrentYear(currentYearData, data);
      this.chartData.labels = [...data.labels];
      let count: Array<string[]> = [];
      data.chartData.forEach((x: any, i: number) => {
        if (this.chartData.datasets[i]) {
          this.chartData.datasets[i].label = x?.labels;
          this.chartData.datasets[i].data = x.data.map((q: number) =>
            !q ? null : q
          );
        }
        const countData: string[] = x.data;
        count.push(countData);
        this.chart?.chart?.update();
      });
      this.totalCounts = this.getSumOfYValues(count).slice(
        this.showCurrentyearLength - 4,
        this.showCurrentyearLength
      );
      this.totalCountsFull = this.getSumOfYValues(count);

      if (
        currentYearData != this.currentYear &&
        data.labels.includes(this.currentYear)
      ) {
        this.totalCounts[this.currentYear % 10] = 0;
      }
    } else {
      this.setDefaultChartDataset();
      this.chart?.chart?.update();
    }

    this.setTempValues(data);
    this.setChartOptions();
  }

  @Input() set selectedFilters(data: SelectedFilters) {
    this.filteredData = data;
    if (data && Object.keys(data).length > 0) {
      this.getFilteredData();
    }
  }

  @Input() set headerLabel(data) {
    this.otherPrgmLabel = data;
  }

  public async getFilteredData() {
    const chartOptions = JSON.parse(JSON.stringify(this.chartOption));
    let hasNonAllValue = false;
    if (
      this.filteredData != undefined &&
      this.filteredData != null &&
      Object.keys(this.filteredData).length > 0
    ) {
      hasNonAllValue = Object.values(this.filteredData).some((value) => {
        return (
          value !== 'all' &&
          !(Array.isArray(value) && value.every((item) => item === 'all'))
        );
      });
    }
    if (hasNonAllValue) {
      this.chartOptionXMin = studentEnrollmentChartCount.chartOptionInitXMin;
      this.chartOptionXMax = window.innerWidth > 500 ? 11 : 5;
      const labelLength = window.innerWidth > 500 ? 12 : 6;
      if (this.showCurrentyearLength <= labelLength) {
        this.disableArrowTrue = false;
      }
      this.disableRighttrue = false;
      this.disableLefttrue = true;
    } else {
      this.sharedService.yearFormat$.subscribe((format: boolean) => {
        if (format) {
          this.chartOptionXMin =
            parseInt(`${new Date().getFullYear()}`.slice(3, 4), 10) - 2;
          this.chartOptionXMax =
            parseInt(`${new Date().getFullYear()}`.slice(3, 4), 10) + 1;
        } else {
          this.chartOptionXMin =
            parseInt(`${new Date().getFullYear()}`.slice(3, 4), 10) - 3;
          this.chartOptionXMax =
            parseInt(`${new Date().getFullYear()}`.slice(3, 4), 10);
        }
      });
      this.disableArrowTrue = true;
      this.disableRighttrue = true;
      this.disableLefttrue = false;
    }
    chartOptions.scales.x.max = this.chartOptionXMax;
    this.updateChartData(this.chartOptionXMin, this.chartOptionXMax);
    this.chartOption = chartOptions;
  }
  // Getting Sum value of chart data sets
  public getSumOfYValues(arr: any) {
    const numRows = arr.length;
    const numCols = arr[0]?.length;
    const sums = [];
    for (let j = 0; j < numCols; j++) {
      let sum = 0;
      let noSum = false;
      for (let i = 0; i < numRows; i++) {
        sum += arr[i][j];
        if (arr[i][j] != null) {
          noSum = true;
        }
      }
      if (sum != 0) {
        sums.push(sum);
      } else {
        sums.push('');
      }
    }
    return sums;
  }
  public ngOnInit(): void {
    this.sharedService.lightColorMode$.subscribe((NavVal: boolean) => {
      if (NavVal === true) {
        this.graphBackgroundColor = [
          '#D0AC62',
          '#9BE2BE',
          '#9797B9',
          '#E9838B',
          '#85D6EC',
        ];
        this.graphLightColor = true;
      } else {
        this.graphBackgroundColor = [
          '#84C7F0',
          '#F3D278',
          '#FA9885',
          '#D9A9F4',
          '#F5B171',
        ];
        this.graphLightColor = false;
      }
      this.chartData.datasets.forEach((x, i: number) => {
        if (this.chartData.datasets[i]) {
          this.chartData.datasets[i].backgroundColor =
            this.graphBackgroundColor[i];
          this.chartData.datasets[i].hoverBackgroundColor =
            this.graphBackgroundColor[i];
        }
      });
      this.chart?.chart?.update();
    });
    this.sharedService.openOtherProgram$.subscribe((isShow: boolean) => {
      this.isShowOther = isShow;
    });
    this.programInfo = this.otherProgramService.getProgramWithContent();
  }

  public generateDataset(
    backgroundColor: string,
    hoverBackgroundColor: string
  ) {
    return {
      backgroundColor,
      barThickness: 40,
      borderColor: 'white',
      borderWidth: 1,
      data: [],
      hoverBackgroundColor,
      hoverBorderColor: 'white',
      label: '',
    };
  }

  public openOtherProgramPopup() {
    if (window.innerWidth < 575) {
      this.sharedService.openOtherProgramMob.next(true);
      this.sharedService.FloatingMenuEvent.next(false);
    } else {
      this.sharedService.openOtherProgram.next(true);
    }

    if (this.otherPrgmLabel.length + 1 != this.totalInfo.length) {
      this.totalInfo.push(0);
    }
    let titleInfo: OtherProgramTitle = {
      popupTotalTitle: 'Student Enrollments By Year',
      popupTitle: ChartType.studentEnrollment,
    };
    this.otherProgramService.bindDataToStorage(
      titleInfo,
      this.otherProgramDetails,
      this.otherPrgmLabel,
      this.totalInfo,
      this.totalYearWiseInfo,
      true,
      false
    );
  }

  public arrowRightMove() {
    let hasNonAllValue = false;
    if (
      this.filteredData != undefined &&
      this.filteredData != null &&
      Object.keys(this.filteredData).length > 0
    ) {
      hasNonAllValue = Object.values(this.filteredData).some((value) => {
        return (
          value !== 'all' &&
          !(Array.isArray(value) && value.every((item) => item === 'all'))
        );
      });
    }
    let count = 4;
    if (hasNonAllValue) {
      count = window.innerWidth > 500 ? 12 : 6;
    }
    this.disableLefttrue = false;
    this.chartOptionXMin = this.chartOptionXMin + 1;
    this.chartOptionXMax = this.chartOptionXMax + 1;
    if (this.chartOptionXMax >= this.totalCountsFull?.length - 1) {
      this.disableRighttrue = true;
      this.chartOptionXMax = this.totalCountsFull.length - 1;
      this.chartOptionXMin = this.totalCountsFull.length - count;
    }
    this.updateChartData(this.chartOptionXMin, this.chartOptionXMax);
  }

  public arrowLeftMove() {
    let hasNonAllValue = false;
    if (
      this.filteredData != undefined &&
      this.filteredData != null &&
      Object.keys(this.filteredData).length > 0
    ) {
      hasNonAllValue = Object.values(this.filteredData).some((value) => {
        return (
          value !== 'all' &&
          !(Array.isArray(value) && value.every((item) => item === 'all'))
        );
      });
    }
    let count = 3;
    if (hasNonAllValue) {
      count = window.innerWidth > 500 ? 11 : 5;
    }
    this.disableRighttrue = false;
    this.sharedService.yearFormat$.subscribe((format: boolean) => {
      if (format === true) {
        this.chartOptionXMin = this.chartOptionXMin - 1;
        this.chartOptionXMax = this.chartOptionXMax - 1;
      } else {
        this.chartOptionXMin = this.chartOptionXMin - 1;
        this.chartOptionXMax = this.chartOptionXMax - 1;
      }
    });
    if (this.chartOptionXMin <= 0) {
      this.disableLefttrue = true;
      this.chartOptionXMin = 0;
      this.chartOptionXMax = count;
    }
    this.updateChartData(this.chartOptionXMin, this.chartOptionXMax);
  }

  public updateChartData(min: number, max: number) {
    this.totalCounts = this.totalCountsFull.slice(min, max + 1);
    let newLabelSet = JSON.parse(JSON.stringify(this.tempChartLabels));
    let newDataSet = JSON.parse(JSON.stringify(this.tempChartData));
    this.chartData.labels = [...newLabelSet.slice(min, max + 1)];
    if (!!newDataSet && Array.isArray(newDataSet) && newDataSet.length > 0) {
      newDataSet?.forEach((x: any, i: number) => {
        if (this.chartData.datasets[i]) {
          this.chartData.datasets[i].label = x?.labels;
          this.chartData.datasets[i].data = x.data
            .slice(min, max + 1)
            .map((q: number) => (!q ? null : q));
        }
      });
    }
    this.chart?.chart?.update();
  }

  public async setChartOptions() {
    if (
      this.showCurrentyearLength > studentEnrollmentChartCount.chartYearLength
    ) {
      this.disableArrowTrue = true;
      this.updateChartData(
        this.showCurrentyearLength - 4,
        this.showCurrentyearLength
      );
      this.disableRighttrue = true;
      this.disableLefttrue = false;
    } else {
      this.disableArrowTrue = false;
      this.chartOptionXMin = studentEnrollmentChartCount.chartOptionInitXMin;
      this.chartOptionXMax = studentEnrollmentChartCount.chartOptionInitXMax;
      this.updateChartData(this.chartOptionXMin, this.chartOptionXMax);
      this.disableRighttrue = false;
      this.disableLefttrue = false;
    }
    await this.getFilteredData();
  }

  public async setTempValues(data: VerticalChartDataSet) {
    if (this.chartData.labels) {
      this.tempChartLabels = JSON.parse(JSON.stringify(this.chartData.labels));
    } else {
      this.tempChartLabels = [];
    }

    if (data?.chartData) {
      this.tempChartData = JSON.parse(JSON.stringify(data?.chartData));
    } else {
      this.tempChartData = null;
    }
  }

  public async setDefaultChartDataset() {
    this.totalCounts = [];
    this.chartData.datasets.forEach((x: any, i: number) => {
      if (this.chartData.datasets[i]) {
        this.chartData.labels = [];
        this.chartData.datasets[i].label = undefined;
        this.chartData.datasets[i].data = [];
      }
    });
  }

  public async checkCurrentYear(
    currentYearData: number,
    data: VerticalChartDataSet
  ) {
    let labelLength = data.labels;
    if (Number(currentYearData) == Number(this.currentYear)) {
      labelLength[labelLength.length - 1] = `${
        labelLength[labelLength.length - 1]
      }* YTD`;
    } else if (labelLength.includes(`${this.currentYear}* YTD`)) {
      labelLength[this.currentYear % 10] = `${this.currentYear}* YTD`;
    }
  }
}
