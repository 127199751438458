import { Component, OnInit } from '@angular/core';
import { FirebaseResponse, NewOrganization } from '../../../shared/components/model/customtypes-model.interface';
import { FirebaseStorageService } from '../../../shared/services/firebase/firebase-storage-service';
import { ApiService } from '../../../shared/services/api.service';
import { AnalyticsService } from '../../../shared/services/analytics/analytics.service';
import { SharedService } from '../../../shared/services/shared.service';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  public headerAreaHeight = 0;
  public orgList = {};
  public organizationsList: NewOrganization[] = [];
  public organizationData = {};
  constructor(
    private readonly firebaseStorageService: FirebaseStorageService,
    public apiService: ApiService,
    private readonly analyticService: AnalyticsService,
    private readonly sharedService: SharedService
  ) { }
  public async ngOnInit() {
    await this.getStatusData();
    this.sharedService.addOrg$.subscribe(async (data: any) => {
      if (data === false) {
        await this.getStatusData();
      }
    });
  }

  public ngAfterViewInit(): void {
    this.headerAreaHeight = window.innerHeight - 120;
  }

  public async getStatusData() {
    const folderName =  'partner-portal/clinical-status';
    const fileName = 'clinical-status.json';
    let data: FirebaseResponse = await this.firebaseStorageService.getStorageFile(
      folderName,
      fileName,
      2
    );
    if (!data || !data.isFileExist) {
      await this.getOrgList();
    } else {
      this.organizationData = await data.response;
      // Extract all IDs from orgData for comparison
      const orgDataIds = await new Set(Object.values(this.organizationData).map((org: any) => org.id));
      (await this.apiService.getOrganizationList()).subscribe(async (data) => {
        this.orgList = data;
        this.organizationsList = Object.keys(this.orgList).map(
          (key) => this.orgList[key]
        );
        const missingIds = this.organizationsList
        .filter(org => !orgDataIds.has(org.id));
        // Find extra IDs in orgData that are not in orgList
        const orgListIds = new Set(this.organizationsList.map(org => org.id));
        const extraInOrgData = Object.keys(this.organizationData).filter(
          key => !orgListIds.has(this.organizationData[key].id)
        );
        // Remove extra entries from orgData
        this.organizationData = Object.entries(this.organizationData)
        .filter(([key]) => !extraInOrgData.includes(key))  // Filter out unwanted keys
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
        if (missingIds.length) {
          await this.getPartnershipInfo(missingIds);
        }
      });
    }
  }

  public async getOrgList() {
    (await this.apiService.getOrganizationList()).subscribe(async (data) => {
      this.orgList = data;
      this.organizationsList = Object.keys(this.orgList).map(
        (key) => this.orgList[key]
      );
      await this.getPartnershipInfo(this.organizationsList);
    });
  }

  public async getPartnershipInfo(orgList) {
    orgList.forEach(async org => {
      await this.apiService.getOverviewObservables(org.id).subscribe(async (data: any) => {
        const partnershipInfo: any = this.filterPartnershipInfo(data);
        this.organizationData[org.name] = {
          id: org.id,
          status: partnershipInfo?.length ? true : false,
        };
        await this.uploadData();
      });
    });
  }

  public async uploadData() {
    this.analyticService.uploadClinicalStatustoFirebase(this.organizationData, 'clinical-status');
  }

  public filterPartnershipInfo(data: any): any[] {
    return data[0]?.items
      .filter((items: any) => items.contentNodes?.excerpt?.value === 'Logo' && items.contentNodes?.title?.value === 'Chamberlain University')
      .map((items: any) => ({
        ...items,
        status: data[1]?.contractList?.filter(
          (x: any) => 'CU Clinical Agreement' === x.RecordType.Name
        ),
      }))?.[0]?.status?.filter(status => status.Agreement_End_Date__c != '' && status.Agreement_End_Date__c != undefined && status.Agreement_End_Date__c != null && (status.Stage__c === 'Active' || status.Stage__c === 'Expired'));
  }
}
