<div class="row">
  <div class="d-flex">
    <div class="col-11">
      <div class="legend">
        <div
          class="custom-legend horizontal-sp"
          [ngClass]="{ lightColor: graphLightColor }"
        >
          <ul>
            <li *ngFor="let item of progressInfo">
              <span></span>
              <div>
                <small>{{ item.content }}</small
                >{{ item.name }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-1 text-center">
      <span class="grey">Total</span>
    </div>
  </div>
  <div class="d-flex">
    <div class="col-11 col-sm-11 col-md-11 col-lg-11 chart">
      <canvas
        #canvasEl
        baseChart
        [data]="chartData"
        [plugins]="chartPlugin"
        [options]="chartOption"
        [type]="chartType"
        (click)="studentWithdrawnReason($event)"
      >
      </canvas>
      <div #popup class="popup" [ngStyle]="popupStyle" *ngIf="showPopup">
        <div class="popup-content">
          <h4>{{ popupLabel }}</h4>
          <p><span [ngStyle]="popupColor"></span>{{ popupContent }}</p>
          <ul>
            <li *ngFor="let item of withdrawnReasons">
              <span>{{ item.reason }} :</span> {{ item.count }}
            </li>
          </ul>
        </div>
        <div class="popup-arrow"></div>
      </div>
      <div
        class="question-align pointer-cursor margin-left-10 question-icon-container"
        (click)="openOtherProgramPopup()"
        (keydown)="openOtherProgramPopup()"
      >
        <span class="question-icon-wraper icon-Others-Icon"> </span>
      </div>
    </div>
    <div class="col-1">
      <div class="total-sec">
        <div class="text-center">
          <ng-container *ngFor="let data of datas?.chartData; let i = index">
            <span class="grey">{{ data?.data?.total }}</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  #tooltip
  class="tooltip"
  [style.left.px]="tooltipPositionX"
  [style.top.px]="tooltipPositionY"
>
  {{ tooltipContent }}
</div>
