// Draw border for learner analytics charts
export const drawBorder = {
  id: 'border',
  beforeDraw: (canvas: any) => {
    const ctx = canvas.ctx;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2 + 5;
    const radius = 145;
    const borderWidth = 1;
    // set the line dash to [5, 5] for a dashed border
    ctx.setLineDash([5, 5]);
    // draw the outer border
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    ctx.lineWidth = borderWidth;
    ctx.strokeStyle = window.innerWidth > 700 ? '#e1e1e1' : 'transparent';
    ctx.stroke();
    ctx.save();
  },
};
// Lerner Analytics chart inner in total count data display
export const doughnutInnerText1 = {
  id: 'text1',
  beforeDraw(chart: any) {
    const width = chart.width;
    const height = chart.height;
    const data = chart.data.datasets[0].data;
    const ctx = chart.ctx;
    ctx.restore();
    let fontSize;
    if (window.innerWidth > 1900) {
      fontSize = (height / 210).toFixed(2);
    } else if (window.innerWidth > 700) {
      fontSize = (height / 160).toFixed(2);
    } else {
      fontSize = (height / 120).toFixed(2);
    }
    ctx.font = `700 ${fontSize}em sans-serif`;
    ctx.fillStyle = '#082469';
    ctx.textBaseline = 'middle';
    const text = data.reduce((a: number, b: number) => a + b);
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    let textY = height / 2;
    if (window.innerWidth > 1900) {
      textY = height / 2.2;
    } else {
      textY = height / 2;
    }
    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};
// Lerner Analytics chart inner in "Total title"
export const doughnutInnerText2 = {
  id: 'text',
  beforeDraw(chart: any) {
    const width = chart.width;
    const height = chart.height;
    const ctx = chart.ctx;
    ctx.restore();
    let fontSize;
    if (window.innerWidth > 1900) {
      fontSize = (height / 240).toFixed(2);
    } else if (window.innerWidth > 700) {
      fontSize = (height / 210).toFixed(2);
    } else {
      fontSize = (height / 120).toFixed(2);
    }
    ctx.font = `700 ${fontSize}em sans-serif`;
    ctx.fillStyle = '#082469';
    ctx.textBaseline = 'middle';
    const text = 'Total';
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 1.7;
    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};

// Alumni graph data label line

export const doughnutLabelsLine = {
  id: 'doughnutLabelsLine',
  afterDraw(chart) {
    const {
      ctx,
      chartArea: { width, height },
    } = chart;
    chart.data.datasets.forEach((dataset, i) => {
      chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
        const { x, y } = datapoint.tooltipPosition();
        const halfWidth = width / 2;
        const halfHeight = height / 2;

        const { xLine, yLine } = calculateLinePositions(
          x,
          y,
          halfWidth,
          halfHeight,
          window.innerWidth
        );

        drawConnectingLine(ctx, x, y, xLine, yLine, x >= halfWidth);

        if (
          chart.data.datasets[0].data[index] != undefined &&
          chart.data.datasets[0].data[index] != 0
        ) {
          drawLabel(
            ctx,
            chart.data.datasets[0].data[index],
            xLine,
            yLine,
            x >= halfWidth
          );
        }
      });
    });
  },
};

export function calculateLinePositions(
  x,
  y,
  halfWidth,
  halfHeight,
  innerWidth
) {
  let xLine;
  let yLine;

  if (innerWidth > 500) {
    xLine = x >= halfWidth ? x + 35 : x - 45;
    yLine = y >= halfHeight ? y + 20 : y - 30;
  } else {
    xLine = x >= halfWidth ? x + 15 : x - 15;
    yLine = y >= halfHeight ? y + 10 : y - 10;
  }

  return { xLine, yLine };
}

export function drawConnectingLine(ctx, x, y, xLine, yLine, isRightSide) {
  const extraLine = isRightSide ? 15 : -25;
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(xLine, yLine);
  ctx.lineTo(xLine + extraLine, yLine);
  ctx.strokeStyle = '#A1AAC1';
  ctx.lineWidth = 2;
}

export function drawLabel(ctx, text, xLine, yLine, isRightSide) {
  const textPosition = isRightSide ? 'left' : 'right';
  const plusFive = isRightSide ? 5 : -5;
  const extraLine = isRightSide ? 15 : -25;

  ctx.font = '12px Arial';
  ctx.textAlign = textPosition;
  ctx.textBaseline = 'middle';
  ctx.fillStyle = 'black';
  ctx.stroke();
  ctx.fillText(text, xLine + extraLine + plusFive, yLine);
}

// Alumni graph background color
export const backgroundColorPlugin = {
  id: 'customCanvasBackgroundColor',
  afterDatasetsDraw: (chart) => {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((element, index) => {
        const model = element;
        const centerX = model.x;
        const centerY = model.y;
        // Draw an inner shadow for each data point
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.shadowColor = '#E4E9F6';
        ctx.shadowBlur = 2;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.beginPath();
        ctx.arc(centerX, centerY, model.innerRadius - 10, Math.PI, 0);
        ctx.fillStyle = 'white'; // Set the background color
        if (
          chart.data.datasets[0].data[index] != undefined &&
          chart.data.datasets[0].data[index] != 0
        ) {
          ctx.fill();
        }
        ctx.restore();
      });
    });
  },
};

export const greaterThanFiveCount = {
  id: 'greaterThanFiveCount',
  afterDatasetsDraw: (chart) => {
    const ctx = chart.ctx;
    const minValidValue = Math.min(
      ...chart.data.datasets
        .flatMap((dataset: any) => Object.values(dataset.data)) // Extract all values from the data objects
        .filter((value: number) => typeof value === 'number' && value >= 5) // Keep only numbers >= 5
    );
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((bar, index) => {
        const value = dataset.data[index];

        // Check for values less than or equal to 5
        if (
          value <= minValidValue &&
          value > 0 &&
          dataset.label === 'Students withdrawn from program'
        ) {
          // Replace numeric value with "< 5"
          const label = '<5';
          const barWidth = bar.base - bar.x;
          const x = bar.x + barWidth / 2; // Center horizontally
          const y = bar.y + 1; // Center vertically

          // Draw the custom "< 5" label
          ctx.fillStyle = '#fff'; // White text color for visibility
          ctx.font = '10px Arial';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(label, x, y);
        }
      });
    });
  },
};
export const greaterThanFiveCountAGD = {
  id: 'greaterThanFiveCountAGD',
  afterDatasetsDraw: (chart) => {
    const ctx = chart.ctx;
    const minValidValue = Math.min(
      ...chart.data.datasets
        .flatMap((dataset: any) => Object.values(dataset.data)) // Extract all values from the data objects
        .filter((value: number) => typeof value === 'number' && value >= 5) // Keep only numbers >= 5
    );
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((bar, index) => {
        const value = dataset.data[index];
        // Check for values less than or equal to 5
        if (
          typeof value === 'number' &&
          value <= minValidValue &&
          value > 0
        ) {
          // Replace numeric value with "< 5"
          const label = '<5';
          const { x, y } = bar.getCenterPoint();

          // Draw the custom "< 5" label
          ctx.fillStyle = '#000';
          ctx.font = '12px Arial';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(label, x, y);
        }
      });
    });
  },
};
export const dataEmpty = {
  id: 'dataEmpty',
  beforeDraw(chart) {
    const isDataEmpty = chart.data.datasets.every(dataset =>
      dataset.data.every(value => value === null || value === 0 || value === undefined)
    );
    if (isDataEmpty) {
      const { ctx, chartArea } = chart;
      ctx.save();
      ctx.font = '16px Arial';
      ctx.fillStyle = 'gray';
      ctx.textAlign = 'center';
      ctx.fillText(
        'No results found for the selected criteria',
        (chartArea.left + chartArea.right) / 2,
        (chartArea.top + chartArea.bottom) / 2
      );
      ctx.restore();
    }
  },
}
