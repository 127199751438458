import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { HttpClient } from '@angular/common/http';
import 'firebase/storage';
import {
  ListResult,
  StorageReference,
  listAll,
  ref,
} from '@angular/fire/storage';
import { FirebaseResponse } from '../../components/model/customtypes-model.interface';

@Injectable({
  providedIn: 'root',
})
export class FirebaseStorageService {
  public storage: any;
  public userFileName: string = '';
  public storageRef: any;
  public fireBaseStorageRef: any;

  constructor(
    private readonly http: HttpClient,
    public fireBaseStorage: AngularFireStorage
  ) {
    this.storage = this.fireBaseStorage?.storage;
  }

  public async initializeStorage(folderName: string) {
    this.storage = this.fireBaseStorage?.storage;
    this.storageRef = ref(this.storage, `${folderName}`);
    this.fireBaseStorageRef = this.fireBaseStorage.ref(`${folderName}`);
  }

  public async checkFileExistance(fileStorage: ListResult, pathNumber: number) {
    if (
      this.userFileName == null ||
      this.userFileName == undefined ||
      this.userFileName == ''
    ) {
      return null;
    }

    return fileStorage.items.find(
      (itemRef: StorageReference) => { 
        const pathSegments = itemRef.fullPath.split('/');
        return this.userFileName === pathSegments[pathNumber];
      }
    );
  }

  public async getTheDownloadURL(): Promise<string> {
    let fileUrl = '';

    const objectRef = this.fireBaseStorageRef?.child(`${this.userFileName}`);

    await objectRef
      .getDownloadURL()
      .toPromise()
      .then(async (url) => {
        const parsedUrl = new URL(url);
        fileUrl = parsedUrl.href;
      });

    return fileUrl;
  }

  public async downLoadFileFromStorage(): Promise<FirebaseResponse> {
    let returnResponse: FirebaseResponse = {
      isSuccess: false,
      isFileExist: true,
      response: {},
    };

    let fileUrl = await this.getTheDownloadURL();

    if (fileUrl) {
      try {
        const res = await this.http.get(fileUrl).toPromise();
        returnResponse.isSuccess = true;
        returnResponse.response = res as any;
      } catch (error) {
        returnResponse.isSuccess = false;
      }
    }
    return returnResponse;
  }

  public async getStorageFile(
    folderName: string,
    fileName: string,
    pathNumber: number
  ): Promise<FirebaseResponse> {
    this.userFileName = fileName;
    await this.initializeStorage(folderName);

    let returnResponse: FirebaseResponse = {
      isSuccess: false,
      isFileExist: true,
      response: {},
    };

    if (
      folderName == null ||
      folderName == undefined ||
      folderName == '' ||
      fileName == null ||
      fileName == undefined ||
      fileName == ''
    ) {
      return returnResponse;
    }
    return listAll(this.storageRef)
      ?.then(async (res: ListResult) => {
        const file = await this.checkFileExistance(res, pathNumber);
        // All the items under listRef.
        if (!file) {
          returnResponse.isFileExist = false;
          return returnResponse;
        } else {
          return this.downLoadFileFromStorage();
        }
      })
      .catch(async (error) => {
        return returnResponse;
      });
  }

  public async saveUserFile(
    fileName: string,
    folderName: string,
    fileContent: any
  ) {
    if (
      fileName != null &&
      fileName != undefined &&
      fileName != '' &&
      folderName != null &&
      folderName != undefined &&
      folderName != ''
    ) {
      await this.initializeStorage(folderName);

      const blob = new Blob([JSON.stringify(fileContent)], {
        type: 'application/json',
      });

      const fileRef = this.fireBaseStorageRef.child(fileName);
      fileRef.put(blob);
    }
  }
}
